import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../layouts/ja";
import SEO from "../components/seo";

export default class LoyaltyUpdatePage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="Loalty Update | "
          keyword="Loalty Update"
          decription="Loalty Update"
        />

        <div id="contentWrap">
          <div id="index_content" className="admin-setting">
            <div>&nbsp;</div>
            <div style={{ textAlign: "center" }}>
              <span style={{ fontSize: "22px" }}>
                <span
                  style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
                  <strong>AYANA REWARDS LOYALTY UPDATE</strong>
                </span>
              </span>
            </div>
            <ul style={{ fontWeight: "700" }}>
              <li>
                <span
                  style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
                  <span style={{ fontSize: "16px" }}>
                    <strong>
                      <strong>Status Update</strong>
                    </strong>
                  </span>
                </span>
              </li>
            </ul>
            <span style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
              <span style={{ fontSize: "16px" }}>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;For gold members, only 1,500
                points required a year to maintain gold status while to be
                upgraded to Platinum, gold members just need to
                collect&nbsp;5,000 points a year.
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;For platinum&nbsp;members,
                only 5,000 points required a year to maintain platinum
                status.&nbsp;
              </span>
            </span>
            <br />
            <ul>
              <li>
                <span
                  style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
                  <span style={{ fontSize: "16px;" }}>
                    <strong>Status Extension</strong>
                  </span>
                </span>
              </li>
            </ul>
            <span style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
              <span style={{ fontSize: "16px" }}>
                <strong>​&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong>
                All members whose status was scheduled to be downgraded on
                March, 2020 &ndash; December, 2020 will be automatically
                extended until December 31, 2020.
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;If 1,000 points have been
                collected throughout the period, member will receive a tier
                status extension.
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Otherwise, member will be
                downgraded on January 1, 2021.
              </span>
            </span>
            <br />
            <ul>
              <li>
                <span
                  style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
                  <span style={{ fontSize: "16px" }}>
                    <strong>Points Extension&nbsp;</strong>
                  </span>
                </span>
              </li>
            </ul>
            <span style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
              <span style={{ fontSize: "16px" }}>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Instead of 2 years, all points
                issued starting from March 2018 will be valid for 10 years.
                Members will have more time to collect and redeem points to the
                dream rewards.
              </span>
            </span>
            <br />
            <ul>
              <li>
                <span
                  style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
                  <strong>
                    <span fontSize="" fontWeight="" new="" times="">
                      Nights Stay Points Redemption
                    </span>
                  </strong>
                </span>
              </li>
            </ul>
            <span style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
              <span style={{ fontSize: "16px" }}>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;We know the current travel
                environment&nbsp;may limit your ability to stay with us. Thus,
                we will reduce the number of points required to stay at any of
                our Hotels.&nbsp;
              </span>
              <br />
              <span new="" style={{ fontSize: "16px" }} times="">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3 nights stay at AYANA Komodo
                Resort for 22,000 points
              </span>
              <br />
              <span new="" style={{ fontSize: "16px" }} times="">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2 nights stay at AYANA Komodo
                Resort for 11,000 points
              </span>
              <br />
              <span new="" style={{ fontSize: "16px" }} times="">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3 nights stay at RIMBA
                Jimbaran BALI by AYANA for 15,000 points
              </span>
              <br />
              <span new="" style={{ fontSize: "16px" }} times="">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2 nights stay at RIMBA
                Jimbaran BALI by AYANA for 7,500 points
              </span>
              <br />
              <span new="" style={{ fontSize: "16px" }} times="">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3 nights stay at AYANA
                Midplaza, JAKARTA for 11,000 points
              </span>
              <br />
              <span new="" style={{ fontSize: "16px" }} times="">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2 nights stay at AYANA
                Midplaza, JAKARTA for 5,500 points
              </span>
              <br />
              <span new="" style={{ fontSize: "16px" }} times="">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3 nights stay at Delonix Hotel
                Karawang for 5,000 points
              </span>
              <br />
              <span new="" style={{ fontSize: "16px" }} times="">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2 nights stay at Delonix Hotel
                Karawang for 2,500 points
              </span>
              <br />
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <br />
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;* Redeem period until July 30th,
              2020 and stay period until July 31st, 2020
              <br />
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;* Redeem through
              <a href="https://ayanarewards.com/reward">
                <span style={{ color: "#0000ff" }}>
                  ayanarewards.com/reward
                </span>
              </a>
            </span>
            <br />
            <br />
            <span style={{ fontFamily: "trebuchet ms,helvetica,sans-serif" }}>
              <span style={{ fontSize: "16px" }}>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;For more information, please
                reach us via email at&nbsp;
                <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a>
              </span>
            </span>
            <br />
            <br />
          </div>
        </div>
      </Layout>
    );
  }
}
